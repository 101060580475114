/* Resetting margins and padding */
* {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
}

/* Setting the background color for the entire page */
html, body, #root {
  height: 100%;
  background-color: #001926; /* Home page background color */
  color: purple;
  font-family: 'Inter', sans-serif;
}

/* Ensure the root container extends fully */
#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

/* Footer specific background color */
.footer-background {
  background-color: #041013; /* Footer background color */
  width: 100%;
  flex-shrink: 0;
}

/* Ensuring content before footer grows to take up remaining space */
.content {
  flex-grow: 1;
}
